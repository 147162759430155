import ScrollAnimation from "react-animate-on-scroll";
import "./Partners.css"
import { InfiniteMovingContainers } from "../ui/infinite-moving-containers";

//TODO: partners no new scroll

const styles: { [key: string]: React.CSSProperties } = {
    container: {
        marginBottom:"40px",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        color:"white",
    },
    partnersContainer:{
        display:"flex",
        flexDirection:"row",
        alignContent:"center",
        justifyContent:"center",
        flexWrap:"wrap",
        gap:"20px",
        height:"30px"
    },
    heading:{
        textShadow:"2px 2px black",
        fontSize:"30px",
        color:"white",
        fontFamily:"batmfa",
        textAlign:"center",
        margin:"10px",
    }
} as const;

function PartnerImage({src, alt, link}: {src: string, alt: string, link: string}): JSX.Element {
    return (
        <a style={{alignSelf:"center"}} href={link} target="_blank" rel="noopener noreferrer" className="h-full flex">
            <img src={src} alt={alt} className="image" />
        </a>
    )
}

export default function Partners(): JSX.Element {
    const partnerData = [
        {
            src: "/partners/pinksale.png",
            alt: "Pinksale",
            link: "https://pinksale.finance/"
        },
        {
            src: "/partners/dexview.png",
            alt: "Dexview",
            link: "https://www.dexview.com/"
        },
        // {
        //     src: "/partners/solscan.svg",
        //     alt: "Solscan",
        //     link: "https://solscan.io/"
        // },
        // {
        //     src: "/partners/coingecko.png",
        //     alt: "Coin Gecko",
        //     link: "https://www.coingecko.com/"
        // },
        // {
        //     src: "/partners/coinmarketcap.png",
        //     alt: "Coin Market Cap",
        //     link: "https://coinmarketcap.com/"
        // },
        // {
        //     src: "/partners/dexscreener.png",
        //     alt: "Dexscreener",
        //     link: "https://dexscreener.com/"
        // },
        {
            src: "/partners/drpc.svg",
            alt: "DRPC",
            link: "https://drpc.org/"
        },
        {
            src: "/partners/quicknode.png",
            alt: "Quicknode",
            link: "https://www.quicknode.com/"
        },
        {
            src: "/partners/grappletools.png",
            alt: "Grapple tools",
            link: "https://www.grapple.tools/"
        },
        {
            src: "/partners/sigma.png",
            alt: "Sigma",
            link: "https://t.me/SigmaBotPortal"
        }
    ]

    const items = partnerData.map((partner, index) => {
        return ()=><PartnerImage key={index} src={partner.src} alt={partner.alt} link={partner.link}/>
    })

    return (
        <ScrollAnimation animateIn="fadeInUp" animateOnce>
            <div style={styles.container} className="relative">
                <h1 style={styles.heading}>Partners</h1>
                <div style={styles.partnersContainer} className="partnersContainer relative">
                    <div className="absolute w-screen h-full">
                        <InfiniteMovingContainers items={items} speed="slow" pauseOnHover={false}/>
                    </div>
                </div>
                {/* <div style={styles.partnersContainer} className="partnersContainer">
                    {partnerData.map((partner, index) => {
                        return <PartnerImage key={index} src={partner.src} alt={partner.alt} link={partner.link}/>
                    })}
                </div> */}
            </div>
        </ScrollAnimation>
    )
}