import ScrollAnimation from "react-animate-on-scroll";
//import { Tabs, Timeline } from "antd";
import { SteppedLineTo } from 'react-lineto';
import React, { useEffect, useState } from "react";
import { ArticleOutlined, BugReportOutlined, CampaignOutlined, CircleOutlined, GroupOutlined, PersonPinCircleRounded, RocketLaunchOutlined, StorefrontOutlined, VerifiedOutlined } from "@mui/icons-material";
import { SparklesCore } from "../ui/sparkles";

import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import LaptopMacIcon from '@mui/icons-material/LaptopMac';
import HotelIcon from '@mui/icons-material/Hotel';
import RepeatIcon from '@mui/icons-material/Repeat';
import Typography from '@mui/material/Typography';
import { IconUser } from "@tabler/icons-react";
import "./Roadmap.css";

//TODO: make multiple

interface timelineItem{
    title: string,
    description: string,
    status: "completed" | "pending"
    iconElement: JSX.Element
}

const phase_1: timelineItem[] = [
    {
        title: "Socials",
        status: "completed",
        iconElement: <GroupOutlined/>,
        description: "Create twitter, telegram accounts"
    },
    {
        title: "Whitepaper",
        status: "completed",
        iconElement: <ArticleOutlined/>,
        description: "Publish the whitepaper"
    },
    {
        title: "Testing",
        status: "completed",
        iconElement: <BugReportOutlined/>,
        description: "Fix any bugs with the bot"
    },
    {
        title: "Inform",
        status: "completed",
        iconElement: <CampaignOutlined/>,
        description: "Announce the token launch date"
    },
    {
        title: "Launch",
        status: "completed",
        iconElement: <RocketLaunchOutlined/>,
        description: "Launch the first open version of the bot"
    
    }
]

const phase_2: timelineItem[] = [
    {
        title: "Analysis",
        status: "completed",
        iconElement: <ArticleOutlined/>,
        description: "Analyze feedback from the bot"
    },
    {
        title: "Deploy",
        status: "pending",
        iconElement: <RocketLaunchOutlined/>,
        description: "Deploy the token"
    },
    {
        title: "Presale",
        status: "pending",
        iconElement: <RocketLaunchOutlined/>,
        description: "Start the token presale on Pinksale"
    },
    {
        title: "Audit",
        status: "pending",
        iconElement: <VerifiedOutlined/>,
        description: "Perform a token audit"
    },
    {
        title: "Marketing",
        status: "pending",
        iconElement: <StorefrontOutlined/>,
        description: "Start the first marketing campaign"
    },
]

function CustomizedTimeline({timelineObjects} : {timelineObjects: timelineItem[]}) {
  return (
    <div style={{fontFamily:"batmfa"}} className="roadmapObject">
        <Timeline position="alternate">
            {timelineObjects.map((timelineObject, index) => {
                return (
                    <TimelineItem>
                        <TimelineSeparator>
                            <TimelineConnector />
                            <TimelineDot variant={`${timelineObject.status == "completed" ? "filled" : "outlined"}`} style={{ backgroundColor: `${timelineObject.status == "completed" ? "rgb(0,222,0)" : ""}` }}>
                                {timelineObject.iconElement}
                            </TimelineDot>
                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: '12px', px: 2 }}>
                            <Typography  component="span" fontStyle={{
                                fontFamily: "batmfa"
                            }}>
                                {timelineObject.title}
                            </Typography>
                            <Typography fontStyle={{
                                fontFamily: "batmfa",
                                fontSize: "11px",
                            }} style={{ color: "lightgrey" }}>{timelineObject.description}</Typography>
                        </TimelineContent>
                    </TimelineItem>
                )
            })}
        </Timeline>
    </div>
  );
}

const styles: { [key: string]: React.CSSProperties } = {
    container: {
        marginBottom: "40px",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        color: "white",
        position:"relative",
        
    },
    roadmapsContainer:{
        marginBottom: "0",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        color: "white",
        position:"relative",
        fontFamily:"batmfa",
    },
    roadmapContainer: {
        display: "flex",
        flexDirection: "row",
        alignContent: "center",
        justifyContent: "center",
        flexWrap: "wrap",
        gap: "20px",
        padding: "20px",
        zIndex: 100,
    },
    heading:{
        textShadow:"2px 2px black",
        fontSize:"30px",
        color:"white",
        fontFamily:"batmfa",
        textAlign:"center",
        margin:"10px",
    },
    linesContainer:{
        display: "flex",
        flexDirection: "column",
        alignContent: "center",
        justifyContent: "center",
        width: "100%",
        height: "100%",
        filter:"blur(1px)",
        position:"absolute",
        top:0,
        left:0,
        zIndex:-1
    }
} as const;

const phases: timelineItem[][] = [phase_1, phase_2]

export default function Roadmap(): JSX.Element {

    

    return (
        <ScrollAnimation animateIn="fadeInUp" animateOnce>
            <div style={styles.container} id="roadmap" className="relative">
                <Background/>
                <h1 style={styles.heading}>Roadmap</h1>
                        <div style={styles.roadmapsContainer} id="roadmap">
                            <RoadmapContent/>
                            {/* <div className={`roadmap`}>
                                <CustomizedTimeline timelineObjects={phase_1}/> 
                            </div> */}
                            {/* { PhaseObject(phases[0].phases,0) } */}
                            {/* { PhaseObject(phases[1].phases,1) } */}
                            {/* { Lines() } */}
                        </div>
            </div >
        </ScrollAnimation> 
    )
}

function RoadmapContent(){
    const [phaseNum, setPhase] = useState(0)

    function canPhaseIncreace(){
        return phaseNum < phases.length - 1
    }

    function canPhaseDecrease(){
        return phaseNum > 0
    }

    function nextPhase(){
        if(canPhaseIncreace()){
            setPhase(phaseNum => phaseNum + 1)
        }
    }

    function previousPhase(){
        if(canPhaseDecrease()){
            setPhase(phaseNum => phaseNum - 1)
        }
    }

    return (
        <>
            <div className="flex flex-row align-middle justify-center items-center gap-10">
                <button className={`roadmapButton ${!canPhaseDecrease() ? "inactive" : "active"} left`} onClick={()=>{previousPhase()}}>{"<"}</button>
                <h2 className="text-[1.5rem]" style={{fontFamily:"batmfa"}}>Phase {phaseNum + 1}</h2>
                <button className={`roadmapButton ${!canPhaseIncreace() ? "inactive" : "active"} right`}  onClick={()=>{nextPhase()}}>{">"}</button>
            </div>  
            {
                phases.map((phase, index) => {
                    return (
                        <>
                            <div className={`roadmap ${phaseNum == index ? "active" : "inactive"} ${phaseNum > index ? "left" : "right"} flex flex-col items-center`}>
                                <CustomizedTimeline timelineObjects={phase}/>
                            </div>
                        </>
                    )
                })
            }
        </>
    )
}

function Background(){
    return (
        <div className="absolute w-screen h-full bg-[#2a05fc49] -z-20">
                    <SparklesCore
                        id="tsparticlesfullpage"
                        background="transparent"
                        minSize={0.6}
                        maxSize={1.4}
                        particleDensity={100}
                        className="w-full h-full -z-20 absolute"
                        particleColor="#FFFFFF"
                    />
                    <div className="absolute h-full w-full z-10" style={{
                        background:"linear-gradient(to bottom, rgba(0,0,0,0) 60%, rgba(0,0,0,1))"
                    }}/>
                    <div className="absolute h-full w-full" style={{
                        background:"linear-gradient(to left, rgba(0,0,0,0) 50%, rgba(0,0,0,1))"
                    }}/>
                    <div className="absolute h-full w-full" style={{
                        background:"linear-gradient(to right, rgba(0,0,0,0) 50%, rgba(0,0,0,1))"
                    }}/>
                    <div className="absolute h-full w-full" style={{
                        background:"linear-gradient(to top, rgba(0,0,0,0) 60%, rgba(0,0,0,1))"
                    }}/>
                </div>
    )
}

/*
const phase1 = {
    title:"Phase 1 - Preparation",
    phases:[
        {
            text: "Socials",
            status: "completed"
        },
        {
            text: "Bot testing w/ whitelist",
            status: "pending"
        },
        {
            text: "Announce token launch date",
            status: "pending"
        },
        {
            text: "Publish whitepaper",
            status: "completed"
        },
        {
            text: "Marketing campaign 1",
            status: "pending"
        },
        {
            text: "Launch cleaner bot",
            status: "pending"
        }
    ]
}

const phase2 = {
    title:"Phase 2 - Presale",
    phases:[
        {
            text: "Analysis & feedback",
            status: "completed"
        },
        {
            text: "Deploy token",
            status: "pending"
        },
        {
            text: "Token presale",
            status: "pending"
        },
        {
            text: "Token audit",
            status: "pending"
        },
        {
            text: "Token launch day reveal",
            status: "pending"
        },
        {
            text: "Marketing campaign 2",
            status: "pending"
        },
        {
            text: "Listing on exchanges",
            status: "pending"
        },
        {
            text: "End of token presale",
            status: "pending"
        },
        {
            text: "Marketing campaign 3",
            status: "pending"
        }
    ]
}

const phase3 = {
    title:"Phase 3 - Token",
    phases:[
        {
            text: "Presale token distribution",
            status: "pending"
        },
        {
            text: "Airdrop reward for bot users",
            status: "pending"
        },
        {
            text: "Token launch",
            status: "pending"
        },
        {
            text: "Marketing campaign 1",
            status: "pending"
        },
        {
            text: "Marketing campaign 2",
            status: "pending"
        }
    ]
}

const phase4 = {
    title:"Phase 4 - Growth",
    phases:[
        {
            text: "Releasing a browser version of the cleaner bot",
            status: "pending"
        },
    ]
}
*/