import ScrollAnimation from "react-animate-on-scroll";
import { HoverEffect } from "../ui/card-hover-effect";
import { WavyBackground } from "../ui/wavy-background";

export default function HowToUseBot(): JSX.Element {
    return (
        <ScrollAnimation animateIn="fadeInUp" animateOnce>
            <div style={{fontFamily:"batmfa"}} className="flex flex-col justify-center items-center relative" id="howtouse">
                <div className="absolute w-screen h-full bg-[#2a05fc49] -z-20">
                    <WavyBackground className="absolute w-full h-full top-0"/>
                    <div className="absolute h-full w-full z-10" style={{
                        background:"linear-gradient(to bottom, rgba(0,0,0,0) 60%, rgba(0,0,0,1))"
                    }}/>
                    <div className="absolute h-full w-full" style={{
                        background:"linear-gradient(to left, rgba(0,0,0,0) 50%, rgba(0,0,0,1))"
                    }}/>
                    <div className="absolute h-full w-full" style={{
                        background:"linear-gradient(to right, rgba(0,0,0,0) 50%, rgba(0,0,0,1))"
                    }}/>
                    <div className="absolute h-full w-full" style={{
                        background:"linear-gradient(to top, rgba(0,0,0,0) 60%, rgba(0,0,0,1))"
                    }}/>
                </div>
                <h1>How to use the bot</h1>
                <button className="inline-flex h-12 animate-shimmer items-center justify-center rounded-md border border-slate-800 bg-[linear-gradient(110deg,#000103,45%,#1e2631,55%,#000103)] bg-[length:200%_100%] px-6 font-medium text-slate-400 transition-colors focus:border-slate-800" onClick={()=>window.open("https://t.me/SolCleanerBot","_blank")}>
                    Try it out
                </button>
                <div className="max-w-5xl mx-auto px-8">
                    <HoverEffect items={[{
                        description: "Import your wallets",
                        title: "Step 1"
                    },{
                        description: "Evaluate for expected profit",
                        title: "Step 2"
                    },{
                        description: "Clean the wallets",
                        title: "Step 3"
                    },{
                        description: "Send funds to a single wallet, receive airdrop",
                        title: "Step 4" 
                    }]}/>
                </div>
            </div>
        </ScrollAnimation>
    )
}