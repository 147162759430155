import * as React from 'react';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Drawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
//import ToggleColorMode from './ToggleColorMode';

const logoStyle = {
    width: '35px',
    marginLeft: '12px',
    marginRight: '12px',
    height: 'auto',
    cursor: 'pointer',
};

//TODO: (telegram bot) make it so it opens a new bot with buttons to other tools that are not working yet (showing comming soon text)

function TopBar() {
    const [open, setOpen] = React.useState(false);

    const toggleDrawer = (newOpen: boolean) => () => {
        setOpen(newOpen);
    };

    const scrollToSection = (sectionId: string) => {
        const sectionElement = document.getElementById(sectionId);
        const offset = 128;
        if (sectionElement) {
            const targetScroll = sectionElement.offsetTop - offset;
            sectionElement.scrollIntoView({ behavior: 'smooth', block: 'start'});
            // window.scrollTo({
            //     top: targetScroll,
            //     behavior: 'smooth',
            // });
            setOpen(false);
        }
    };

    return (
        <div>
            <AppBar
                position="fixed"
                sx={{
                    boxShadow: 0,
                    bgcolor: 'transparent',
                    backgroundImage: 'none',
                    mt: 2,
                }}
                style={{
                    fontFamily: "font-family: 'Courier New', Courier, monospace;",
                }}
            >
                <Container maxWidth="lg">
                    <Toolbar
                        variant="regular"
                        sx={(theme) => ({
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexShrink: 0,
                            borderRadius: '999px',
                            bgcolor:
                                // theme.palette.mode === 'light'
                                //   ? 'rgba(255, 255, 255, 0.4)'
                                'rgba(0, 0, 0, 0.6)',
                            maxHeight: 40,
                            width: '100%',
                            border: '1px solid',
                            borderColor: 'divider',
                            boxShadow:
                                // theme.palette.mode === 'light'
                                //   ? `0 0 1px rgba(85, 166, 246, 0.1), 1px 1.5px 2px -1px rgba(85, 166, 246, 0.15), 4px 4px 12px -2.5px rgba(85, 166, 246, 0.15)`
                                '0 0 1px rgba(2, 31, 59, 0.7), 1px 1.5px 2px -1px rgba(2, 31, 59, 0.65), 4px 4px 12px -2.5px rgba(2, 31, 59, 0.65)',
                        })}
                    >
                        <Box
                            sx={{
                                flexGrow: 1,
                                display: 'flex',
                                alignItems: 'center',
                                ml: '-18px',
                                px: 0,
                            }}
                            
                        >
                            <MenuItem
                                onClick={() => scrollToSection('headingContent')}
                                sx={{ py: '6px', px: '12px', flex:"1" }}
                            >
                                <img
                                    src={'/logo.png'}
                                    style={logoStyle}
                                    alt="logo of sitemark"
                                />
                            </MenuItem>
                            <Box sx={{ display: { xs: 'none', md: 'flex', alignItems: 'center', justifyContent:"center", flex:"20" } }}>
                                <MenuItem
                                    onClick={()=>window.open("https://t.me/SolCleanerBot","_blank")}
                                    sx={{ py: '6px', px: '12px' }}
                                >
                                    <Typography variant="body2" color="text.white" fontFamily={"batmfa, 'Courier New', Courier, monospace;"}>
                                        Telegram bot
                                    </Typography>
                                </MenuItem>
                                <MenuItem
                                    onClick={() => scrollToSection('tokenomics')}
                                    sx={{ py: '6px', px: '12px' }}
                                >
                                    <Typography variant="body2" color="text.white" fontFamily={"batmfa, 'Courier New', Courier, monospace;"}>
                                        Tokenomics
                                    </Typography>
                                </MenuItem>
                                <MenuItem
                                    onClick={() => scrollToSection('howtouse')}
                                    sx={{ py: '6px', px: '12px' }}
                                >
                                    <Typography variant="body2" color="text.white" fontFamily={"batmfa, 'Courier New', Courier, monospace;"}>
                                        How to use
                                    </Typography>
                                </MenuItem>
                                <MenuItem
                                    onClick={() => scrollToSection('roadmap')}
                                    sx={{ py: '6px', px: '12px' }}
                                >
                                    <Typography variant="body2" color="text.white" fontFamily={"batmfa, 'Courier New', Courier, monospace;"}>
                                        Roadmap
                                    </Typography>
                                </MenuItem>
                                <MenuItem
                                    onClick={() => scrollToSection('contacts')}
                                    sx={{ py: '6px', px: '12px' }}
                                >
                                    <Typography variant="body2" color="text.white" fontFamily={"batmfa, 'Courier New', Courier, monospace;"}>
                                        Contacts
                                    </Typography>
                                </MenuItem>
                            </Box>
                            <MenuItem
                                onClick={() => scrollToSection('headingContent')}
                                sx={{ py: '6px', px: '12px', flex:"1", filter:"opacity(0)" }}
                            >
                                <img
                                    src={'/logo.png'}
                                    style={logoStyle}
                                    alt="logo of sitemark"
                                />
                            </MenuItem>
                        </Box>
                        <Box sx={{ display: { sm: '', md: 'none' } }}>
                            <Button
                                variant="text"
                                color="primary"
                                aria-label="menu"
                                onClick={toggleDrawer(true)}
                                sx={{ minWidth: '30px', p: '4px' }}
                            >
                                <MenuIcon />
                            </Button>
                            <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
                                <Box
                                    sx={{
                                        minWidth: '60dvw',
                                        p: 2,
                                        backgroundColor: 'rgba(0, 0, 0, 0.93)',
                                        flexGrow: 1,
                                        color: "white"
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'end',
                                            flexGrow: 1,
                                            color: "white"
                                        }}
                                    >
                                        {/* <ToggleColorMode mode={mode} toggleColorMode={toggleColorMode} /> */}
                                    </Box>
                                    <MenuItem onClick={()=>window.open("https://t.me/SolCleanerBot","_blank")}>
                                        Telegram bot
                                    </MenuItem>
                                    <MenuItem onClick={() => scrollToSection('tokenomics')}>
                                        Tokenomics
                                    </MenuItem>
                                    <MenuItem onClick={() => scrollToSection('howtouse')}>
                                        How to use
                                    </MenuItem>
                                    <MenuItem onClick={() => scrollToSection('roadmap')}>
                                        Roadmap
                                    </MenuItem>
                                    <MenuItem onClick={() => scrollToSection('contacts')}>
                                        Contacts
                                    </MenuItem>
                                </Box>
                            </Drawer>
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
        </div>
    );
}

export default TopBar;