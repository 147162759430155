import TopBar from '../components/HomepageFeatures/AppBar';
import Tokenomics from '../components/HomepageFeatures/Tokenomics';
import HeadingContent from '../components/HomepageFeatures/HeadingContent';
import styles from '../index.module.css';
import Partners from '../components/HomepageFeatures/Partners';
import Contacts from '../components/HomepageFeatures/Contacts';
import Footer from '../components/HomepageFeatures/Footer';
import AboutToken from '../components/HomepageFeatures/AboutToken';
import OnlineSolanaWalletCleaner from '../components/HomepageFeatures/OnlineSolanaWalletCleaner';
import Roadmap from '../components/HomepageFeatures/Roadmap';
import { SparklesCore } from '../components/ui/sparkles';
import HowToUseBot from '../components/HomepageFeatures/HowToUseBot';
import { useEffect, useState } from 'react';
import GlobalStatistics from '../components/HomepageFeatures/RecentCleans';
import RecentCleans from '../components/HomepageFeatures/RecentCleans';

//TODO: make scroll to top on page load!

interface cleaningResult{
    time: number,
    lampAmount: string,
    partialAddresses: string[],
}

interface transferResult{
    time: number,
    partialAddress: string,
    amount: string,
}

interface statistics{
    numOfCleanings: number,
    numOfTransfers: number,
    numOfEvaluations: number,
    totalLampsCleaned: string,
    latestCleaning:cleaningResult[],
    latestTransfers: transferResult[],
}

export default function Home(): JSX.Element {
    useEffect(() => {
        if(process.env.NODE_ENV != "development") window.scrollTo(0, 0)
    }, []) 

    const [statistics, setStatistics] = useState<statistics>();

    function getStatistics(){
        fetch("https://api.cleanerbot.org/overallUsageStatistics/getStatistics")
            .then(res => res.json())
            .then(
                (result) => {
                    setStatistics(result);
                }
            ).catch(e=>console.log(e));
    }

    useEffect(()=>{
        getStatistics();
    },[])

    return (
      <div className="absolute top-0 left-0 flex justify-center align-middle w-full bg-black flex-col overflow-hidden">
          <TopBar/>
          {/* <div className="w-full absolute inset-0 h-screen z-50 bg-black">
              <SparklesCore
                  id="tsparticlesfullpage"
                  background="transparent"
                  minSize={0.6}
                  maxSize={1.4}
                  particleDensity={100}
                  className="w-full h-full"
                  particleColor="#FFFFFF"
              />
          </div> */}
          <HeadingContent lampscleaned={statistics?.totalLampsCleaned}/>
          <div className="flex flex-col justify-center align-middle self-center max-w-[1000px] z-20 w-full gap-[5rem]">
              {/* <AboutToken/> */}
              {/* <GlobalStatistics/> */}
              <Tokenomics/>
              <HowToUseBot/>
              <Roadmap/>
              <RecentCleans cleanResults={statistics?.latestCleaning || []}/>
              <OnlineSolanaWalletCleaner/>
              <Partners/>
              <Contacts/>  
              <Footer/>
          </div>
      </div>
    );
  }