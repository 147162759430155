import { VerticalAlignBottomOutlined } from '@mui/icons-material';
import { Button, Steps } from 'antd';
import ScrollAnimation from 'react-animate-on-scroll';

const styles: { [key: string]: React.CSSProperties } = {
    container: {
        marginBottom:"40px",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        color:"white",
        fontFamily:"batmfa",
    },
    browserToolContainer:{
        backgroundColor: "rgba(55, 55, 55, 0.3)",
        padding: "20px",
        margin: "20px",
        borderRadius: "25px",
        maxWidth: "600px",
        minHeight: "400px",
        display:"flex",
        flexDirection:"column",
        justifyContent:"center",
        alignItems:"center",
        gap:"20px",
        pointerEvents:"none",
        position:"relative"
    },
    toolTopBar:{
        display:"flex",
        justifyContent:"space-between",
        width:"100%",
        flex:1,
        filter:"blur(2px)",
        color: "white",
        pointerEvents:"none",
    },
    toolContent:{
        display:"flex",
        filter:"blur(2px) brightness(0.3)",
        flexDirection:"column",
        justifyContent:"center",
        alignItems:"center",
        gap:"20px",
        flex:5,
        pointerEvents:"none",
    },
    heading:{
        fontFamily:"batmfa",
        color:"white",
        textAlign:"center",
    },
    overlayErrorContainer:{
        display:"flex",
        flexDirection:"column",
        justifyContent:"center",
        alignItems:"center",
        width:"100%",
        borderRadius: "25px",
        height:"100%",
        boxShadow:"0 0 10px 5px rgba(0,0,0,0.5)",
        top:0,
        left:0,
        zIndex:50,
        fontSize:"1.5em",
        position:"absolute",
        textAlign:"center",
        pointerEvents:"all"
    }
} as const;

export default function OnlineSolanaWalletCleaner(): JSX.Element {

    return (
        <div style={styles.container}>
            <ScrollAnimation animateIn="fadeInUp" animateOnce>
                <h1 style={styles.heading}>Browser cleaner tool</h1>
                <div style={styles.browserToolContainer}>
                    <div style={styles.overlayErrorContainer}>The tool is not yet available in the browser. <br/> Try out telegram tool instead! </div>
                    <div style={styles.toolTopBar}>
                        <Steps
                            direction='horizontal'
                            size="small"
                            current={0}
                            items={[
                                {
                                    title: 'Import',
                                },
                                {
                                    title: 'Evaluate',
                                },
                                {
                                    title: 'Clean',
                                },
                                {
                                    title: 'Transfer',
                                },
                            ]}
                        />
                    </div>
                    <div style={styles.toolContent}>
                        <h1 style={styles.heading}>Import the wallets you want to clean</h1>
                        <Button icon={<VerticalAlignBottomOutlined/>}  type="primary" size={'large'} style={{display:"flex",alignContent:"center",fontFamily:"batmfa"}}>
                            Import
                        </Button>
                    </div>
                </div>
            </ScrollAnimation>
        </div>
    )
}