import HomepageButton from "./input/Button";
import { SparklesCore } from "../ui/sparkles";
import { Vortex } from "../ui/vortex";

const styles: { [key: string]: React.CSSProperties } = {
    container: {
        paddingTop: "40px",
        marginBottom:"20px",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    heading:{
        textShadow:"2px 2px black",
        // fontSize:"60px",
        color:"white",
        fontFamily:"batmfa",
        textAlign:"center",
        margin:"10px",
    },
    heading2:{
        textShadow:"2px 2px black",
        // fontSize:"30px",
        color:"white",
        fontFamily:"batmfa",
        textAlign:"center",
        margin:"10px",
        marginBottom:"20px",
    },
    heading3:{
        textShadow:"2px 2px black",
        // fontSize:"20px",
        color:"white",
        fontFamily:"batmfa",
        textAlign:"center",
        margin:"10px",
        marginBottom:"30px",
    }
} as const;

export default function HeadingContent({lampscleaned}:{lampscleaned?: string}): JSX.Element {
    const solcleaned = (parseInt(lampscleaned || "0")*0.000000001).toFixed(4);
    return (
        <div style={styles.container} className="relative h-[80vh] min-h-[500px]" id="headingContent">
            <div className="absolute w-full h-full top-0 left-0 z-10" style={{
                background:"linear-gradient(to bottom, rgba(255,0,0,0), rgba(0,0,0,1))"
            }}>
                <div className="w-full h-full relative overflow-hidden">
                    <div className="absolute w-full h-full z-10" style={{
                        background:"linear-gradient(to bottom, rgba(255,0,0,0), rgba(0,0,0,1))"
                    }}></div>
                    {/* <SparklesCore
                        id="tsparticlesfullpage"
                        background="transparent"
                        minSize={0.6}
                        maxSize={1.4}
                        particleDensity={100}
                        className="w-full h-full -z-20 absolute"
                        particleColor="#FFFFFF"
                    /> */}
                    <Vortex
                        backgroundColor="transparent"
                        rangeY={800}
                        particleCount={400}
                        baseHue={120}
                        className="w-full h-full -z-20 absolute"
                    ></Vortex>
                </div>
          </div>
          <div className="h-full w-full absolute z-30 flex align-middle justify-center flex-col content-center items-center">
                <h1 style={styles.heading} className="md:text-[60px] text-[35px]"> Solana cleaner bot </h1>
                <h2 style={styles.heading2} className="md:text-[35px] text-[25px]">Reclaim  the SOL you don't even know about</h2>
                <h3 style={styles.heading3} className="text-white md:text-[20px] text-[17px]">A total of <text className="text-[#00aeff]">{solcleaned}</text> SOL already cleaned</h3>
                <div style={{fontFamily:"batmfa"}} className="flex gap-4 flex-wrap justify-center">
                    <button className="p-[3px] relative" onClick={()=>window.open("https://docs.techtrinity.org","_blank")}>
                        <div className="absolute inset-0 bg-gradient-to-r from-blue-300 to-blue-800 rounded-lg" />
                        <div className="px-8 py-2 bg-black rounded-[6px]  relative group transition duration-200 text-white hover:bg-transparent">
                            Whitepaper
                        </div>
                    </button>
                    <button className="p-[3px] relative" onClick={()=>window.open("https://t.me/SolCleanerBot","_blank")}>
                        <div className="absolute inset-0 bg-gradient-to-r from-blue-400 to-blue-900 rounded-lg" />
                        <div className="px-8 py-2 bg-black rounded-[6px]  relative group transition duration-200 text-white hover:bg-transparent">
                            Telegram bot
                        </div>
                    </button>
                </div>
                {/* <HomepageButton bigger={true} buttonText="Whitepaper" clickCallback={()=>{window.open("https://techtrinity-development.netlify.app","_blank")}}/> */}
          </div>
        </div>
    )
}