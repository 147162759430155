// import '@coreui/coreui/dist/css/coreui.min.css'

// // CoreUI PRO version
// import '@coreui/coreui-pro/dist/css/coreui.min.css'

// import 'bootstrap/dist/css/bootstrap.min.css'
// import 'bootstrap/dist/js/bootstrap.min.js'

import './tokenomics.css'
import ScrollAnimation from "react-animate-on-scroll";

//import { CChart } from '@coreui/react-chartjs'
import HomepageButton from './input/Button'
//import { Cell, Pie, PieChart, ResponsiveContainer } from 'recharts';

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

/*
5%   - exchanges / listing
5%   - team
20% - Pinksale presale
30% - Liquidity pool
40% - Bot users as airdrop distributed over six months, proportional to the volume of bot usage.
(remaining tokens will be burned)

*/

const styles: { [key: string]: React.CSSProperties } = {
    textContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        flex: 1,
        gap: "10px"
    },
    containerText: {
        color: "white",
    },
    chartContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        color: "white",
        maxWidth:"70%",
        flex: 1,
        // pointerEvents:"none",
    },
    heading: {
        textShadow: "2px 2px black",
        fontSize: "40px",
        color: "white",
        fontFamily: "batmfa"
    }
};

export default function Tokenomics(): JSX.Element {

    // const data02 = [
    //     {
    //       "name": "Group A",
    //       "value": 2400
    //     },
    //     {
    //       "name": "Group B",
    //       "value": 4567
    //     },
    //     {
    //       "name": "Group C",
    //       "value": 1398
    //     },
    //     {
    //       "name": "Group D",
    //       "value": 9800
    //     },
    //     {
    //       "name": "Group E",
    //       "value": 3908
    //     },
    //     {
    //       "name": "Group F",
    //       "value": 4800
    //     }
    //   ];

    //   const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

    const data = {
        labels: ['Exchanges', 'Team', 'Pinksale', 'Liquidity', 'Airdrops'],
        datasets: [
            {
                label: 'Percentage (%)',
                data: [5, 5, 20, 30, 40],
                backgroundColor: [
                    'rgba(71, 255, 255, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(57, 255, 25, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(0, 80, 255, 0.2)',
                ],
                borderColor: [
                    'rgba(71, 184, 255, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(57, 203, 25, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(0, 40, 255, 1)',
                ],
                borderWidth: 1,
            },
        ],
    };

    return (
        <div style={{
            width: "100%",
            minHeight: "400px",
            padding:0,
            margin:0,
            fontFamily:"batmfa"
        }}id="tokenomics">
            {/* <ScrollAnimation animateIn="fadeInUp" animateOnce> */}
            <div className="tokenomicsContainer" id="tokenomics">
                <div style={styles.textContainer}>
                    <h1 style={styles.heading}> Tokenomics </h1>
                    <div style={styles.containerText}>
                        <li>
                            5% - Exchanges / listing
                        </li>
                        <li>
                            5% - Team
                        </li>
                        <li>
                            20% - Pinksale presale
                        </li>
                        <li>
                            30% - Liquidity pool
                        </li>
                        <li>
                            40% - Airdrops for bot users
                        </li>
                    </div>
                    <div style={styles.containerText} className='text-center'>
                        All the bot's profit will be turned into tokens and immediatelly burned!
                    </div>
                    <button className="inline-flex h-12 animate-shimmer items-center justify-center rounded-md border border-slate-800 bg-[linear-gradient(110deg,#000103,45%,#1e2631,55%,#000103)] bg-[length:200%_100%] px-6 font-medium text-slate-400 transition-colors focus:border-slate-800" onClick={() => { window.open("https://techtrinity-development.netlify.app/docs/Tokenomics/token","_blank")}}>
                        Learn more
                    </button>
                    {/* <HomepageButton buttonText="Learn more" clickCallback={() => { window.open("https://techtrinity-development.netlify.app/docs/Tokenomics/token","_blank")}} /> */}
                </div>
                <div style={styles.chartContainer}>
                    <Pie data={data} options={{
                        "color": "white",
                        plugins: {
                            legend: {
                                labels: {
                                    color: "white",
                                    // This more specific font property overrides the global property
                                    font: {
                                        size: 16,
                                        family: "batmfa"
                                    }
                                }
                            },
                            tooltip: {
                                "bodyFont": {
                                    family: "batmfa"
                                },
                                "titleFont": {
                                    family: "batmfa"
                                },
                                "displayColors": false,
                            },
                        }
                    }} />
                    {/* <ResponsiveContainer width="100%" height="100%">
                        <PieChart width={1000} height={1000}>
                            <Pie data={data02} dataKey="value" nameKey="name" cx="50%" cy="50%" outerRadius={80} fill="#82ca9d" labelLine={false}
                            label={({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
                                const RADIAN = Math.PI / 180;
                                const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
                                const x = cx + radius * Math.cos(-midAngle * RADIAN);
                                const y = cy + radius * Math.sin(-midAngle * RADIAN);
                            
                                return (
                                    <text style={{zIndex:100}} x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                                        {data02[index].name}
                                    </text>
                                );
                            }}
                            >
                                {data02.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                ))}
                            </Pie>
                        </PieChart>
                    </ResponsiveContainer> */}
                    {/* <CChart
                            customTooltips = {true}
                            type="doughnut"
                            data={{
                                labels: ['Exchanges / listing', 'Team', 'Pinksale presale', 'Liquidity pool', 'Airdrops for bot users', 'Burned'],
                                datasets: [
                                    {
                                        backgroundColor: ['#0b1aed', '#006e8a', '#008a9e', '#00bb9a', '#00d96e', '#75ec18'],
                                        data: [5, 5, 20, 30, 40, 0],
                                        barPercentage: 0.5,
                                        hoverOffset: 10,
                                        borderColor: "black",
                                        hoverBorderCapStyle: "round",
                                        hoverBorderColor: "black",
                                    },
                                ],
                                
                            }}
                            options={{
                                plugins: {
                                    legend: {
                                        labels: {
                                            color: "white",
                                            font:{
                                                family:"batmfa"
                                            },
                                            padding: 7,
                                        },
                                    },
                                    tooltip:{
                                        enabled: false,
                                        titleColor:"white",
                                        bodyColor:"white",
                                        cornerRadius: 10,
                                    },
                                    
                                    title:{
                                        color:"white",
                                    },
                                    decimation:{
                                        enabled: false,
                                        algorithm: "min-max",
                                        
                                    },
                                },
                            }}
                        /> */}
                </div>
                </div>
            {/* </ScrollAnimation> */}
        </div>

    )
}