
import Home from './pages/Home';


export default function App(): JSX.Element {
  //const {siteConfig} = useDocusaurusContext();
  return (
    <Home/>
  );
}
