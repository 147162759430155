const styles: { [key: string]: React.CSSProperties } = {
    container: {
        marginBottom:"40px",
        marginTop:"60px",
        fontSize:"15px",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        color:"white",
        fontFamily:"batmfa",
        textAlign:"center"
    }
} as const;

export default function Footer(): JSX.Element {
    return (
        <div style={styles.container}>
            © Tech Trinity 2024 - All Rights Reserved
        </div>
    )
}