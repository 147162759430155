import ScrollAnimation from "react-animate-on-scroll";

interface cleaningResult{
    time: number,
    lampAmount: string,
    partialAddresses: string[],
}

const backgroundColor = "#0f172a"

function CleanResult({cleanResult}:{cleanResult:cleaningResult}): JSX.Element{
    const solAmount = (parseInt(cleanResult.lampAmount)*0.000000001).toFixed(4);
    return (
        <div style={{fontFamily:"batmfa"}} className="flex flex-row text-white relative">
            <div className={`flex flex-row border p-1 bg-[#0f172a] border-slate-800 rounded-lg w-full relative`}>
                <div className="relative flex flex-[4] overflow-hidden flex-row gap-3">
                    <div className="absolute w-full h-full" style={{
                        background:`linear-gradient(to right, rgba(0,0,0,0), ${backgroundColor})`
                    }}/>
                    {cleanResult.partialAddresses.map((address, index) => {
                        return <p>{address}</p>
                    })}
                </div>
                <p className="text-green-500 flex-[1] text-right">+{solAmount}</p>
            </div>
        </div>
    )
}

export default function RecentCleans({cleanResults}:{cleanResults:cleaningResult[]}): JSX.Element {
    cleanResults = cleanResults.filter((cleanResult) =>  parseInt(cleanResult.lampAmount).toFixed(4) != "0.0000");
    cleanResults = cleanResults.slice(0, 6);
    return (
        <ScrollAnimation animateIn="fadeInUp" animateOnce>
            <div className="flex flex-col align-middle justify-center items-center">
                <h1>Recent clean results</h1>
                <div className="flex flex-col max-w-[600px] w-full relative md:p-0 p-8 gap-1">
                    <div className="absolute w-full rounded-lg overflow-hidden h-full top-0 left-0 z-10" style={{
                        background:`linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,0), rgb(0,0,0) 96%)`
                    }}/>
                    {cleanResults.map((cleanResult, index) => {
                        return <CleanResult cleanResult={cleanResult}/>
                    })}
                </div>
            </div>
        </ScrollAnimation>
    )
}