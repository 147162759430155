import ScrollAnimation from "react-animate-on-scroll";
import "./Contacts.css"
import { InfiniteMovingContainers } from "../ui/infinite-moving-containers";

const styles: { [key: string]: React.CSSProperties } = {
    container: {
        marginBottom:"40px",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        color:"white",
    },
    partnersContainer:{
        display:"flex",
        flexDirection:"row",
        alignContent:"center",
        justifyContent:"center",
        flexWrap:"wrap",
        gap:"20px",
    },
    heading:{
        textShadow:"2px 2px black",
        fontSize:"30px",
        color:"white",
        fontFamily:"batmfa",
        textAlign:"center",
        margin:"10px",
    }
} as const;

function Contact({src, alt, link, text}: {src: string, alt: string, link: string, text: string}): JSX.Element {
    return (
        <a className="contact" href={link} target="_blank" rel="noopener noreferrer">
            <img src={src} alt={alt} className="image" />
            <text className="contactTitle">{text}</text>
        </a>
    )
}

export default function Contacts(): JSX.Element {
    const contactData = [
        {
            src: "/basicLogos/telegram.png",
            alt: "Telegram",
            link: "https://t.me/trinitycleanerbot",
            text: "Telegram",
        },
        {
            src: "/basicLogos/x.png",
            alt: "Twitter",
            link: "https://twitter.com/techtrinity_inc",
            text: "Twitter",
        }
    ]

    return (
        <div style={styles.container} id="contacts">
            <ScrollAnimation animateIn="fadeInUp" animateOnce>
                <h1 style={styles.heading}>Contacts</h1>
                <div style={styles.partnersContainer} className="contactsContainer">
                    {contactData.map((contact, index) => {
                        return <Contact key={index} src={contact.src} alt={contact.alt} link={contact.link} text={contact.text}/>
                    })}
                </div>
            </ScrollAnimation>
        </div>
    )
}